<template>
  <div id="verification">
    <section>
      <b-modal  v-model="showVerification" :width="540" scroll="keep" :on-cancel="handleCloseVerify">
        <h4 class="brand">PROFIT</h4>
        <b-steps
            v-model="activeStep"
            :animated="isAnimated"
            :rounded="isRounded"
            :has-navigation="hasNavigation"
            :icon-prev="prevIcon"
            :icon-next="nextIcon"
            :label-position="labelPosition"
            :mobile-mode="mobileMode">
          <b-step-item step="0" label="Verification" :clickable="isStepsClickable">
            <h2 class="modal-title">Verification</h2>
            <sweetalert-icon  icon="loading"></sweetalert-icon>
            <footer id="account-confirm-loading-footer" class="account-confirm-footer footer">
              <b-button
                  class="submit"
                  type="submit"
                  readonly="true"
              >Please Wait...</b-button>
            </footer>
          </b-step-item>
          <b-step-item step="1" label="Verified" :clickable="isStepsClickable">
<!--            <h2 class="modal-title">Welcome</h2>-->
<!--              <sweetalert-icon  icon="success"></sweetalert-icon>-->
            <div class="xcentered" style="margin:24px 0 24px;display: inline-block;"><img :src="successIcon"  /></div>
            <p class="head-19" style="margin:14px 0 64px;">Welcome</p>
            <footer id="account-confirm-footer" class="account-confirm-footer footer">
<!--              <p class="signup-note">You are now verified and ready to explore PROFIT</p>-->
              <b-button
                  class="submit"
                  type="submit"
                  @click="goToCreateServer()"
              >Create Server</b-button>
              <p class="bottom-link" style="margin-top: 21px;"><a style="color: white" href="#" @click.prevent="handleCloseVerify()">Close</a></p>
            </footer>
          </b-step-item>
          <b-step-item step="2" label="Error" :clickable="isStepsClickable">
<!--            <h2 class="modal-title">Something's wrong!</h2>-->
<!--            <sweetalert-icon  icon="error"></sweetalert-icon>-->
            <div class="xcentered" style="margin:24px 0 24px;display: inline-block;"><img :src="ErrorIcon"  /></div>
            <p class="head-19" style="margin:14px 0 8px;">Email Validation Error</p>
            <p class="head-15" style="margin:0 0 22px;color: #51555A;">Check email link or contact us.</p>
            <footer id="account-confirm-error-footer" class="account-confirm-footer footer">
<!--              <p class="signup-note">{{validationError}}</p>-->
<!--              <p class="signup-note">Re-check email link or contact us.</p>-->
              <b-button
                  class="submit"
                  type="submit"
                  @click="handleCloseVerify()"
              >Close</b-button>
            </footer>
          </b-step-item>
        </b-steps>
      </b-modal>
    </section>
  </div>
</template>

<script>

    import axios from "../axios";
    import SweetalertIcon from 'vue-sweetalert-icons';

    export default {
      name: "Verification",
      components: {
        SweetalertIcon
      },
      data(){
        return{
          isVerified: false,
          showVerification: true,
          hasQuery: null,
          activeStep: 0,
          username: null,
          password: null,
          response: null,
          isAnimated: true,
          isRounded: true,
          isStepsClickable: false,
          hasNavigation: false,
          customNavigation: false,
          prevIcon: 'chevron-left',
          nextIcon: 'chevron-right',
          labelPosition: 'bottom',
          mobileMode: 'minimalist',
          validationError: 'Validation Error',
        }
      },
      computed:{
        get_user(){
          return this.$store.getters.get_user;
        },
        isAuthenticated() {
          //return this.$localStorage.get('accessToken');
          return this.$store.getters.isAuthenticated;
        },
        successIcon() {
          return require('@/assets/img/Success-100.svg');
        },
        ErrorIcon() {
          return require('@/assets/img/TriangleError.svg');
        },
      },
      created() {
        if (this.$route.query && this.$route.query.username && this.$route.query.validation) {
          this.handleVerification();
        } else {
          this.$router.replace('/');
        }
      },
      mounted() {

      },
      methods: {
        async handleVerification() {
          this.loading = true;
          let authData = {
            username: this.$route.query.username,
            validationString: this.$route.query.validation,
          };
          let response = await axios.post('/auth/verify-email', authData);
          if (response.data && response.data.user && response.data.user.emailValidated && response.data.tokens) {
            this.response = response;
            this.$store.commit('set_token', this.response.data.tokens.accessToken);
            this.$store.commit('set_refreshToken', this.response.data.tokens.refreshToken);
            this.$store.commit('set_userDataAfterLogIn', this.response.data.user);
            //this.$localStorage.set('accessToken', this.response.data.tokens.accessToken); // tempo
            //this.$localStorage.set('user', JSON.stringify(this.response.data.user));
            let user = JSON.parse(this.$localStorage.get('user'));
            user.token = this.$localStorage.get('accessToken');
            let result = await this.$store.dispatch('login', user);
            this.$store.dispatch('set_openCloseSidebar', 'close');
            this.activeStep = 1;
          } else {
            if (response.data && response.data.error && response.data.message) {
              this.validationError = response.data.message;
            }
            this.activeStep = 2;
          }
        },
        goToCreateServer() {
          this.showVerification - false;
          this.$router.replace('/');
        },
        handleCloseVerify() {
          this.showVerification - false;
          this.$router.replace('/');
        },


      }
    }
</script>

<style>

  #account-confirm-footer,
  #account-confirm-error-footer,
  #account-confirm-loading-footer {
    /*margin-top: 1em;*/
    background-color: transparent;
    padding: 0;
    width: 100%;
    text-align: center;
  }

  #verification .modal .modal-content {
    background-color: #1e2124 !important;
    max-width: 420px !important;
    padding: 1.5em 1em 1em;
    border-radius: 17px!important;
    /*box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5) !important;*/
  }


</style>
